import axios, { AxiosResponse } from "axios";
import { apiEventMonitorUrl, apiUrl } from "@/env";
import {
  labelledOutputState,
  outputStateHistory,
  summarizedOutputStateHistory,
} from "@/interfaces/output_state";
import { ProcessingAlgorithmModel } from "@/interfaces/processing_algorithm";
import { labelledInfrastructureState } from "@/interfaces/infrastructure_state";
import {
  DeviceCreate,
  Device,
  DeviceUpdate,
  DeviceStatus,
  DeviceStatusCreate,
} from "@/interfaces/devices";
import { Location } from "@/interfaces/location";
import { LocationThreshold } from "@/interfaces/location_threshold";
import {
  InfrastructureCaptureRule,
  InfrastructureCaptureRuleCreate,
  InfrastructureCaptureRuleUpdate,
} from "@/interfaces/infrastructure_capture_rule";
import { SummaryImageConfig } from "@/interfaces/summary_image_config";
import {
  ActivitiesPaginated,
  Activity,
  ActivityCreate,
  ActivityUpdate,
} from "@/interfaces/activity";
import {
  EventMonitorSection,
  EventMonitorSectionCreate,
  EventMonitorSectionUpdate,
  SectionThresholds,
  SectionThresholdsCreate,
  SectionThresholdsUpdate,
} from "@/interfaces/section";
import { AssetCreate } from "@/interfaces/assets";
import {
  EdgeConfigTunable,
  EdgeConfigTunableUpdate,
} from "@/interfaces/edge_config";
import { BackendSyncChanges, BackendSyncItems } from "@/interfaces/sync";

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

function fileHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
}

export const eventMonitor = {
  async getAvailableSubscriptions(token: string): Promise<AxiosResponse> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/messages/unique/`,
      authHeaders(token)
    );
  },
  async getRegisteredModels(token: string): Promise<AxiosResponse> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/observations/registered_models/`,
      authHeaders(token)
    );
  },
  async getOutputStateHistory(
    token: string,
    params: {
      model_id: number;
      start_time: string;
      end_time: string;
      start_lat?: number;
      end_lat?: number;
      start_lon?: number;
      end_lon?: number;
      live: boolean;
      summarised: boolean;
    }
  ): Promise<AxiosResponse<outputStateHistory>> {
    return axios.get(`${apiEventMonitorUrl}/api/v1/output_state/history/`, {
      params,
      ...authHeaders(token),
    });
  },
  async getConveyorReport(
    token: string,
    params: { section_ids: number[]; start_time: string; end_time: string }
  ): Promise<AxiosResponse> {
    return axios.post(
      `${apiEventMonitorUrl}/api/v1/output_state/generate-conveyor-report/`,
      params,
      authHeaders(token)
    );
  },
  async getOutputStateHistorySummarized(
    token: string,
    params: {
      model_id: number;
      start_time: string;
      end_time: string;
    }
  ): Promise<AxiosResponse<summarizedOutputStateHistory>> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/output_state/history-summarized/`,
      { params, ...authHeaders(token) }
    );
  },
  async getOutputStateHistoryLive(
    token: string,
    params: {
      model_id: number;
      device_id?: number;
    }
  ): Promise<AxiosResponse<summarizedOutputStateHistory>> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/output_state/history-live/`,
      { params, ...authHeaders(token) }
    );
  },
  async getOutputStateHistoryAggregated(
    token: string,
    params: {
      model_id: number;
      device_id?: number;
      summary_function: string;
      percentile?: number;
      start_time?: string;
      end_time?: string;
    }
  ): Promise<AxiosResponse<summarizedOutputStateHistory>> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/output_state/history-aggregated/`,
      { params, ...authHeaders(token) }
    );
  },
  async getOutputStateHistory24Hours(
    token: string,
    params: {
      model_id: number;
      device_id?: number;
    }
  ): Promise<AxiosResponse<summarizedOutputStateHistory>> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/output_state/history-24hr/`,
      { params, ...authHeaders(token) }
    );
  },
  async getOutputStateHistoryLastWeek(
    token: string,
    params: {
      model_id: number;
      device_id?: number;
    }
  ): Promise<AxiosResponse<summarizedOutputStateHistory>> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/output_state/history-last-week/`,
      { params, ...authHeaders(token) }
    );
  },
  async getOutputStateCounts(
    token: string,
    params: {
      model_id: number;
      start_time: string;
      end_time: string;
      device_id?: number;
    }
  ): Promise<AxiosResponse<Array<boolean | string>>> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/output_state/calendar-view/`,
      { params, ...authHeaders(token) }
    );
  },
  async getHistoryCounts(
    token: string,
    params: {
      model_id: number;
      value: number;
      start_time: string;
      end_time: string;
      device_id?: number;
    }
  ): Promise<AxiosResponse> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/output_state/history-counts/`,
      { params, ...authHeaders(token) }
    );
  },

  async getOutputStateLabels(
    token: string,
    params: { model_id: number }
  ): Promise<AxiosResponse> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/output_state/labels/${params.model_id}/`,
      {
        params,
        ...authHeaders(token),
      }
    );
  },
  /// Infrastructure state
  async getInfrastructureStateHistory(
    token: string,
    params: {
      model_id: number;
      start_time: string;
      end_time: string;
      live: boolean;
      summarised: boolean;
    }
  ): Promise<AxiosResponse> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/infrastructure_state/history/`,
      { params, ...authHeaders(token) }
    );
  },
  async getInfrastructureStateHistorySummarized(
    token: string,
    params: {
      model_id: number;
      start_time: string;
      end_time: string;
      device_id?: number;
    }
  ): Promise<AxiosResponse> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/infrastructure_state/history-summarized/`,
      { params, ...authHeaders(token) }
    );
  },
  async getInfrastructureStateHistoryLive(
    token: string,
    params: {
      model_id: number;
      device_id?: number;
      asset_id?: number;
    }
  ): Promise<AxiosResponse> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/infrastructure_state/history-live/`,
      { params, ...authHeaders(token) }
    );
  },
  async getInfrastructureStateHistory24Hours(
    token: string,
    params: {
      model_id: number;
      device_id?: number;
      asset_id?: number;
    }
  ): Promise<AxiosResponse> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/infrastructure_state/history-24hr/`,
      { params, ...authHeaders(token) }
    );
  },
  async getInfrastructureStateHistoryLastWeek(
    token: string,
    params: {
      model_id: number;
      device_id?: number;
      asset_id?: number;
    }
  ): Promise<AxiosResponse> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/infrastructure_state/history-last-week/`,
      { params, ...authHeaders(token) }
    );
  },
  async getInfrastructureStateCounts(
    token: string,
    params: {
      model_id: number;
      start_time: string;
      end_time: string;
      device_id?: number;
      asset_id?: number;
    }
  ): Promise<AxiosResponse<Array<boolean | string>>> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/infrastructure_state/calendar-view/`,
      { params, ...authHeaders(token) }
    );
  },
  async getInfrastructureHistoryCounts(
    token: string,
    params: {
      model_id: number;
      start_time: string;
      end_time: string;
      device_id?: number;
      asset_id?: number;
    }
  ): Promise<AxiosResponse> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/infrastructure_state/history-counts/`,
      { params, ...authHeaders(token) }
    );
  },
  async getInfrastructureLabels(
    token: string,
    params: {
      model_id: number;
    }
  ): Promise<AxiosResponse> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/infrastructure_state/labels/`,
      { params, ...authHeaders(token) }
    );
  },
  async getInfrastructureStateHistoryByLatLon(
    token: string,
    params: {
      model_id: number;
      start_lat: number;
      end_lat: number;
      start_lon: number;
      end_lon: number;
      start_time: string;
      end_time: string;
      sort_order?: string;
      device_id?: number;
      asset_id?: number;
    }
  ): Promise<AxiosResponse> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/infrastructure_state/history-by-lat-lon/`,
      { params, ...authHeaders(token) }
    );
  },
  async getInfrastructureStateLabels(
    token: string,
    params?: { model_id?: number; active_only?: boolean }
  ): Promise<AxiosResponse> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/infrastructure_state/labels/`,
      {
        params,
        ...authHeaders(token),
      }
    );
  },
  async getProcessingAlgorithmModels(
    token: string,
    params: { enabled_only: boolean }
  ): Promise<AxiosResponse<ProcessingAlgorithmModel[]>> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/algorithm_model/processing`,
      { params, ...authHeaders(token) }
    );
  },
  async getDevices(token: string): Promise<AxiosResponse<Device[]>> {
    return axios.get(`${apiEventMonitorUrl}/api/v1/device/`, {
      ...authHeaders(token),
    });
  },
  async createDevice(
    token: string,
    payload: DeviceCreate
  ): Promise<AxiosResponse<Device>> {
    return axios.post(
      `${apiEventMonitorUrl}/api/v1/device/`,
      payload,
      authHeaders(token)
    );
  },
  async updateDevice(
    token: string,
    payload: DeviceUpdate
  ): Promise<AxiosResponse<Device>> {
    return axios.put(
      `${apiEventMonitorUrl}/api/v1/device/${payload.id}/`,
      payload,
      authHeaders(token)
    );
  },
  async deleteDevice(
    token: string,
    deviceSerial: string
  ): Promise<AxiosResponse> {
    return axios.delete(`${apiEventMonitorUrl}/api/v1/device/${deviceSerial}`, {
      ...authHeaders(token),
    });
  },
  async getDeviceLocations(
    token: string,
    device_id: number
  ): Promise<AxiosResponse<Location[]>> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/device/${device_id}/locations`,
      {
        ...authHeaders(token),
      }
    );
  },
  async getDeviceStatuses(
    token: string
  ): Promise<AxiosResponse<DeviceStatus[]>> {
    return axios.get(`${apiEventMonitorUrl}/api/v1/device_status/`, {
      ...authHeaders(token),
    });
  },
  async addDeviceStatus(
    token: string,
    payload: DeviceStatusCreate
  ): Promise<AxiosResponse<DeviceStatus>> {
    return axios.post(
      `${apiEventMonitorUrl}/api/v1/device_status/`,
      payload,
      authHeaders(token)
    );
  },
  async deleteDeviceStatus(
    token: string,
    serialNumber: string
  ): Promise<AxiosResponse> {
    return axios.delete(
      `${apiEventMonitorUrl}/api/v1/device_status/${serialNumber}`,
      authHeaders(token)
    );
  },
  // Do the assets
  async getAssets(token: string): Promise<AxiosResponse> {
    return axios.get(`${apiEventMonitorUrl}/api/v1/asset/`, {
      ...authHeaders(token),
    });
  },
  async createAsset(
    token: string,
    params: AssetCreate
  ): Promise<AxiosResponse> {
    return axios.post(`${apiEventMonitorUrl}/api/v1/asset/`, params, {
      ...authHeaders(token),
    });
  },
  async updateAsset(
    token: string,
    params: ActivityUpdate,
    id: number
  ): Promise<AxiosResponse> {
    return axios.put(`${apiEventMonitorUrl}/api/v1/asset/${id}`, params, {
      ...authHeaders(token),
    });
  },
  async deleteAsset(token: string, id: number): Promise<AxiosResponse> {
    return axios.delete(`${apiEventMonitorUrl}/api/v1/asset/${id}`, {
      ...authHeaders(token),
    });
  },
  async getLabelledInfrastructureStates(
    token: string,
    params: {
      model_id: number;
      start_time: string;
      end_time: string;
      sort_order?: string;
      device_id?: number;
      selected_location_ids?: number[];
    }
  ): Promise<AxiosResponse<labelledInfrastructureState[]>> {
    return axios.post(
      `${apiEventMonitorUrl}/api/v1/infrastructure_state/history-by-lat-lon/labelled/`,
      params,
      authHeaders(token)
    );
  },
  async getUniqueMessages(token: string): Promise<AxiosResponse> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/messages/unique/`,
      authHeaders(token)
    );
  },
  async getLabelledOutputStates(
    token: string,
    params: {
      model_id: number;
      start_time: string;
      end_time: string;
      sort_order?: string;
      device_id?: number;
      selected_location_ids?: number[];
    }
  ): Promise<AxiosResponse<labelledOutputState[]>> {
    return axios.post(
      `${apiEventMonitorUrl}/api/v1/output_state/history-by-lat-lon/labelled/`,
      params,
      authHeaders(token)
    );
  },
  async getLocations(
    token: string,
    params?: { skip?: number; limit?: number }
  ): Promise<AxiosResponse<Location[]>> {
    return axios.get(`${apiEventMonitorUrl}/api/v1/location/`, {
      params,
      ...authHeaders(token),
    });
  },
  async getLocationThresholdsByLocationId(
    token: string,
    location_id: number
  ): Promise<AxiosResponse> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/location_threshold/location/${location_id}/`,
      { ...authHeaders(token) }
    );
  },
  async updateLocationThreshold(
    token: string,
    params: LocationThreshold
  ): Promise<AxiosResponse> {
    return axios.put(
      `${apiEventMonitorUrl}/api/v1/location_threshold/`,
      params,
      { ...authHeaders(token) }
    );
  },
  async createInfrastructureCaptureRule(
    token: string,
    params: InfrastructureCaptureRuleCreate
  ): Promise<AxiosResponse<InfrastructureCaptureRule>> {
    return axios.post(
      `${apiEventMonitorUrl}/api/v1/capture_rule/infrastructure_state/`,
      params,
      {
        ...authHeaders(token),
      }
    );
  },
  async getInfrastructureCaptureRules(
    token: string
  ): Promise<AxiosResponse<InfrastructureCaptureRule[]>> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/capture_rule/infrastructure_state/`,
      {
        ...authHeaders(token),
      }
    );
  },
  async deleteInfrastructureCaptureRule(
    token: string,
    capture_rule_id: number
  ): Promise<AxiosResponse> {
    return axios.delete(
      `${apiEventMonitorUrl}/api/v1/capture_rule/infrastructure_state/${capture_rule_id}/`,
      {
        ...authHeaders(token),
      }
    );
  },
  async updateInfrastructureCaptureRule(
    token: string,
    params: InfrastructureCaptureRuleUpdate
  ): Promise<AxiosResponse> {
    return axios.put(
      `${apiEventMonitorUrl}/api/v1/capture_rule/infrastructure_state/`,
      params,
      {
        ...authHeaders(token),
      }
    );
  },
  async getSummaryImageConfig(
    token: string
  ): Promise<AxiosResponse<SummaryImageConfig[]>> {
    return axios.get(`${apiEventMonitorUrl}/api/v1/summary_image_config/`, {
      ...authHeaders(token),
    });
  },
  async updateSummaryImageConfig(
    token: string,
    params: SummaryImageConfig
  ): Promise<AxiosResponse> {
    return axios.put(
      `${apiEventMonitorUrl}/api/v1/summary_image_config/`,
      params,
      {
        ...authHeaders(token),
      }
    );
  },
  async deleteSummaryImageConfig(
    token: string,
    id: number
  ): Promise<AxiosResponse> {
    return axios.delete(
      `${apiEventMonitorUrl}/api/v1/summary_image_config/${id}`,
      {
        ...authHeaders(token),
      }
    );
  },
  async createSummaryImageConfig(
    token: string,
    params: SummaryImageConfig
  ): Promise<AxiosResponse> {
    return axios.post(
      `${apiEventMonitorUrl}/api/v1/summary_image_config/`,
      params,
      {
        ...authHeaders(token),
      }
    );
  },
  async getStatusCounts(token: string): Promise<AxiosResponse> {
    // Get processing status
    return axios.get(`${apiEventMonitorUrl}/api/v1/status/counts`, {
      ...authHeaders(token),
    });
  },
  async getStatus(token: string): Promise<AxiosResponse> {
    // Get processing status
    return axios.get(`${apiEventMonitorUrl}/api/v1/status`, {
      ...authHeaders(token),
    });
  },
  async getActivities(
    token: string,
    params: {
      skip?: number;
      limit?: number;
      sort_by?: string;
      sort_desc?: boolean;
      device_ids?: number[];
      asset_ids?: number[];
      statuses?: string[];
      label_ids?: string[];
      start_time?: string;
      end_time?: string;
    } = {
      skip: 0,
      limit: 10,
    }
  ): Promise<AxiosResponse<ActivitiesPaginated>> {
    // Manually construct the query string for array parameters
    const queryString = Object.entries(params)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${key}=${v}`).join("&");
        }
        return `${key}=${value}`;
      })
      .join("&");

    return axios.get(`${apiEventMonitorUrl}/api/v1/activity/?${queryString}`, {
      ...authHeaders(token),
    });
  },
  async getActivitiesReport(
    token: string,
    params: {
      skip?: number;
      limit?: number;
      sort_by?: string;
      sort_desc?: boolean;
      device_ids?: number[];
      asset_ids?: number[];
      statuses?: string[];
      start_time?: string;
      end_time?: string;
      label_ids?: string[];
    } = {
      skip: 0,
      limit: 10,
    }
  ): Promise<AxiosResponse<Blob>> {
    // Manually construct the query string for array parameters
    const queryString = Object.entries(params)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${key}=${v}`).join("&");
        }
        return `${key}=${value}`;
      })
      .join("&");

    return axios.get(
      `${apiEventMonitorUrl}/api/v1/activity/export/?${queryString}`,
      {
        ...authHeaders(token),
        responseType: "blob",
      }
    );
  },
  async getOpenActivities(token: string): Promise<AxiosResponse<Activity[]>> {
    return axios.get(`${apiEventMonitorUrl}/api/v1/activity/open-by-section`, {
      ...authHeaders(token),
    });
  },
  async createActivity(
    token: string,
    params: ActivityCreate
  ): Promise<AxiosResponse> {
    return axios.post(`${apiEventMonitorUrl}/api/v1/activity/`, params, {
      ...authHeaders(token),
    });
  },
  async getActivity(
    token: string,
    id: number
  ): Promise<AxiosResponse<Activity>> {
    return axios.get(`${apiEventMonitorUrl}/api/v1/activity/${id}`, {
      ...authHeaders(token),
    });
  },
  async updateActivity(
    token: string,
    id: number,
    params: ActivityUpdate
  ): Promise<AxiosResponse<Activity>> {
    return axios.put(`${apiEventMonitorUrl}/api/v1/activity/${id}`, params, {
      ...authHeaders(token),
    });
  },
  async acknowledgeAllOpenActivities(
    token: string,
    params: {
      acknowledged_by: string;
      acknowledged_at?: string;
    }
  ): Promise<AxiosResponse> {
    return axios.post(
      `${apiEventMonitorUrl}/api/v1/activity/acknowledge_all_open`,
      params,
      {
        ...authHeaders(token),
      }
    );
  },
  async deleteActivity(token: string, id: number): Promise<AxiosResponse> {
    return axios.delete(`${apiEventMonitorUrl}/api/v1/activity/${id}`, {
      ...authHeaders(token),
    });
  },
  async getSections(
    token: string
  ): Promise<AxiosResponse<EventMonitorSection[]>> {
    return axios.get(`${apiEventMonitorUrl}/api/v1/section/`, {
      ...authHeaders(token),
    });
  },
  async getSectionsByDeviceId(
    token: string,
    deviceId: number
  ): Promise<AxiosResponse<EventMonitorSection[]>> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/section/by-device-id/${deviceId}`,
      {
        ...authHeaders(token),
      }
    );
  },
  async getSectionLocations(
    token: string,
    sectionId: number
  ): Promise<AxiosResponse<Location[]>> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/section/${sectionId}/locations`,
      {
        ...authHeaders(token),
      }
    );
  },
  async createSection(
    token: string,
    params: EventMonitorSectionCreate
  ): Promise<AxiosResponse<EventMonitorSection>> {
    return axios.post(`${apiEventMonitorUrl}/api/v1/section/`, params, {
      ...authHeaders(token),
    });
  },
  async updateSection(
    token: string,
    sectionId: number,
    params: EventMonitorSectionUpdate
  ): Promise<AxiosResponse<EventMonitorSection>> {
    return axios.put(
      `${apiEventMonitorUrl}/api/v1/section/${sectionId}`,
      params,
      {
        ...authHeaders(token),
      }
    );
  },
  async deleteSection(
    token: string,
    sectionId: number
  ): Promise<AxiosResponse<EventMonitorSection>> {
    return axios.delete(`${apiEventMonitorUrl}/api/v1/section/${sectionId}`, {
      ...authHeaders(token),
    });
  },
  async splitSection(
    token: string,
    sectionId: number,
    splitDistanceM: number,
    newSectionName: string
  ): Promise<AxiosResponse<EventMonitorSection>> {
    return axios.put(
      `${apiEventMonitorUrl}/api/v1/section/${sectionId}/split`,
      { split_distance_m: splitDistanceM, new_section_name: newSectionName },
      {
        ...authHeaders(token),
      }
    );
  },
  // async getSections(
  //   token: string
  // ): Promise<AxiosResponse<EventMonitorSection[]>> {
  //   return axios.get(`${apiEventMonitorUrl}/api/v1/section/`, {
  //     ...authHeaders(token),
  //   });
  // },
  // async getSectionsByDeviceId(
  //   token: string,
  //   deviceId: number
  // ): Promise<AxiosResponse<EventMonitorSection[]>> {
  //   return axios.get(
  //     `${apiEventMonitorUrl}/api/v1/section/by-device-id/${deviceId}`,
  //     {
  //       ...authHeaders(token),
  //     }
  //   );
  // },
  // async getSectionLocations(
  //   token: string,
  //   sectionId: number
  // ): Promise<AxiosResponse<Location[]>> {
  //   return axios.get(
  //     `${apiEventMonitorUrl}/api/v1/section/${sectionId}/locations`,
  //     {
  //       ...authHeaders(token),
  //     }
  //   );
  // },
  async getAllSectionThresholds(
    token: string
  ): Promise<AxiosResponse<SectionThresholds[]>> {
    return axios.get(`${apiEventMonitorUrl}/api/v1/section_thresholds/`, {
      ...authHeaders(token),
    });
  },
  async getSectionThresholdsByDeviceIdAndProcessingAlgorithmId(
    token: string,
    deviceId: number,
    algorithmModelId: number
  ): Promise<AxiosResponse<SectionThresholds[]>> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/section_thresholds/by-device-id-and-model-id/?device_id=${deviceId}&model_id=${algorithmModelId}`,
      {
        ...authHeaders(token),
      }
    );
  },
  async getSectionThresholdsByLocationIdAndProcessingAlgorithmId(
    token: string,
    locationId: number,
    algorithmModelId: number
  ): Promise<AxiosResponse<SectionThresholds>> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/section_thresholds/by-location-id-and-model-id/?location_id=${locationId}&model_id=${algorithmModelId}`,
      {
        ...authHeaders(token),
      }
    );
  },
  async updateSectionThresholds(
    token: string,
    params: SectionThresholdsUpdate
  ): Promise<SectionThresholds> {
    return axios.put(
      `${apiEventMonitorUrl}/api/v1/section_thresholds/`,
      params,
      {
        ...authHeaders(token),
      }
    );
  },
  async createSectionThresholds(
    token: string,
    params: SectionThresholdsCreate
  ): Promise<AxiosResponse<SectionThresholds>> {
    return axios.post(
      `${apiEventMonitorUrl}/api/v1/section_thresholds/`,
      params,
      {
        ...authHeaders(token),
      }
    );
  },
  async deleteSectionThresholds(
    token: string,
    id: number
  ): Promise<AxiosResponse> {
    return axios.delete(
      `${apiEventMonitorUrl}/api/v1/section_thresholds/${id}`,
      {
        ...authHeaders(token),
      }
    );
  },
  async createBulkSectionThresholds(
    token: string,
    params: SectionThresholdsCreate[]
  ): Promise<AxiosResponse> {
    return axios.post(
      `${apiEventMonitorUrl}/api/v1/section_thresholds/bulk/`,
      params,
      {
        ...authHeaders(token),
      }
    );
  },
  async getEdgeConfigTunablesBySerialNumber(
    token: string,
    serialNumber: string
  ): Promise<AxiosResponse<EdgeConfigTunable[]>> {
    return axios.get(
      `${apiEventMonitorUrl}/api/v1/device/${serialNumber}/tunables`,
      {
        ...authHeaders(token),
      }
    );
  },
  async updateEdgeConfigTunable(
    token: string,
    tunableId: number,
    payload: EdgeConfigTunableUpdate,
    restartTimeout = 30
  ): Promise<AxiosResponse<EdgeConfigTunable>> {
    return axios.put(
      `${apiEventMonitorUrl}/api/v1/device/tunable/${tunableId}`,
      payload,
      {
        ...authHeaders(token),
        params: { restart_timeout: restartTimeout },
      }
    );
  },
  async updateEdgeConfigTunables(
    token: string,
    serialNumber: string,
    payload: EdgeConfigTunableUpdate[],
    restartTimeout = 30
  ): Promise<AxiosResponse<EdgeConfigTunable>> {
    return axios.put(
      `${apiEventMonitorUrl}/api/v1/device/${serialNumber}/tunables`,
      payload,
      {
        ...authHeaders(token),
        params: { restart_timeout: restartTimeout },
      }
    );
  },
  async updateModelBundle(
    token: string,
    formData: FormData
  ): Promise<AxiosResponse<void>> {
    return axios.post(
      `${apiEventMonitorUrl}/api/v1/maintenance/update`,
      formData,
      fileHeaders(token)
    );
  },
  async planSync(
    token: string,
    syncItems?: BackendSyncItems
  ): Promise<AxiosResponse<BackendSyncChanges>> {
    return axios.post(
      `${apiEventMonitorUrl}/api/v1/sync/plan-alternate`,
      syncItems,
      {
        ...authHeaders(token),
      }
    );
  },
  async applySync(
    token: string,
    syncItems?: BackendSyncItems
  ): Promise<AxiosResponse<BackendSyncChanges>> {
    return axios.post(
      `${apiEventMonitorUrl}/api/v1/sync/apply-alternate`,
      syncItems,
      {
        ...authHeaders(token),
      }
    );
  },
};
