import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import { State } from "../state";
import {
  commitSetMapZoom,
  commitSetMapCenter,
  commitSetGISLayers,
} from "./mutations";
import { MapState } from "./state";
import { backend } from "@/api/backend";
import { readToken } from "@/store/main/getters";
import { dispatchCheckApiError } from "@/store/main/actions";

type MainContext = ActionContext<MapState, State>;

export const actions = {
  async actionSetZoom(context: MainContext, payload: number): Promise<void> {
    commitSetMapZoom(context, payload);
  },
  async actionSetMapCenter(
    context: MainContext,
    payload: { lat: number; lng: number }
  ): Promise<void> {
    commitSetMapCenter(context, payload);
  },
  async actionGetGISLayers(context: MainContext): Promise<void> {
    try {
      const response = await backend.getGeoJSONLayers(
        context.rootState.main.token
      );
      commitSetGISLayers(context, response.data);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const { dispatch } = getStoreAccessors<MapState | any, State>("");

export const dispatchSetMapZoom = dispatch(actions.actionSetZoom);
export const dispatchSetMapCenter = dispatch(actions.actionSetMapCenter);
export const dispatchActionGetGISLayers = dispatch(actions.actionGetGISLayers);
