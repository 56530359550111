import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { SiteConfigState } from "./state";

const defaultState: SiteConfigState = {
  siteConfigurations: [],
  projectTimeZone: "Etc/UTC",
  demoMode: false,
};

export const siteConfigModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
