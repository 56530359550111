import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

import { mainModule } from "@/store/main";
import { State } from "@/store/state";
import { adminModule } from "@/store/admin";
import { mapModule } from "@/store/worldmap";
import { siteConfigModule } from "@/store/siteconfig";
import { devicesModule } from "@/store/devices";
import { activitiesModule } from "@/store/activities";

Vue.use(Vuex);

const storeOptions: StoreOptions<State> = {
  modules: {
    main: mainModule,
    admin: adminModule,
    map: mapModule,
    siteConfig: siteConfigModule,
    devices: devicesModule,
    activities: activitiesModule,
  },
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
