import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { MapState } from "./state";
import { DisplayMode } from "@/utils";

// Worldmap is a way to share map state across components in our application
// This allows changes to the map in one view to be persisted to other views.
const defaultState: MapState = {
  center: { lat: -31.9543959, lng: 115.8592311 },
  zoom: 15,
  GISLayers: [],
};

export const mapModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
