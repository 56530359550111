import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import { IUserProfile } from "@/interfaces";

export const getters = {
  adminUsers: (state: AdminState): IUserProfile[] => state.users,
  adminOneUser:
    (state: AdminState) =>
    (userId: number): IUserProfile => {
      const filteredUsers = state.users.filter((user) => user.id === userId);
      if (filteredUsers.length > 0) {
        return { ...filteredUsers[0] };
      }
    },
  users: (state: AdminState): IUserProfile[] => state.users,
};

const { read } = getStoreAccessors<AdminState, State>("");

export const readAdminOneUser = read(getters.adminOneUser);
export const readAdminUsers = read(getters.adminUsers);
export const readUsers = read(getters.users);
