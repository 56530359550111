import { State } from "../state";
import { MapState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { DisplayMode } from "@/utils";
import { GISLayerBase } from "@/interfaces/gis_layer";

export const mutations = {
  setMapCenter(state: MapState, payload: { lat: number; lng: number }): void {
    state.center = payload;
  },
  setMapZoom(state: MapState, payload: number): void {
    state.zoom = payload;
  },
  setGISLayers(state: MapState, payload: GISLayerBase[]): void {
    state.GISLayers = payload;
  },
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const { commit } = getStoreAccessors<MapState | any, State>("");

export const commitSetMapCenter = commit(mutations.setMapCenter);
export const commitSetMapZoom = commit(mutations.setMapZoom);
export const commitSetGISLayers = commit(mutations.setGISLayers);
